<template>
    <BasicInformationCard title="Task informatie"
        :editRouterLink="{ name: routeNames.TASK_DETAIL_EDIT_TASK_INFORMATION, params: { clientId: clientId, projectId: projectId, taskId: taskId } }"
        :promises.sync="promises">
        <template v-slot:content>
            <v-container>
                <v-row>
                    <v-col cols="12" md="7">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <BasicInformationItem description="Start datum"
                                    :value="applyDateFormat(taskInformation.startDate?.localTime)" />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <BasicInformationItem description="End datum"
                                    :value="applyDateFormat(taskInformation.endDate?.localTime)" />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <BasicInformationItem description="Duratie"
                                    :value="ISO8601StringToDisplayString(taskInformation.duration)" />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <BasicInformationItem description="Aantal nodige personeel"
                                    :value="taskInformation.usersRequired" />
                            </v-col>
                            <v-col cols="12">
                                <BasicInformationItem description="Beschrijving"
                                    :value="taskInformation.description" />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </BasicInformationCard>
</template>

<script>
import BasicInformationCard from '../shared/BasicInformationCard.vue'
import BasicInformationItem from '../shared/BasicInformationItem.vue'
import routeNames from "@/router/RouteNames"
import { applyDateFormat } from "@/shared/utils/dateUtils"
import { translateTaskFrequency, translateTimeUnit } from "@/shared/utils/translateUtils"
import { ISO8601StringToDisplayString } from "@/shared/utils/timeUtils"

export default {
    name: "TaskInformationComponent",
    props: {
        clientId: {
            type: String,
            required: true
        },
        projectId: {
            type: String,
            required: true
        },
        taskId: {
            type: String,
            required: true
        }
    },
    components: {
        BasicInformationCard,
        BasicInformationItem,
    },
    data() {
        return {
            routeNames,
            taskInformation: {
                startDate: null,
                endDate: null,
                duration: null,
                usersRequired: null,
                description: null
            },
            promises: [
                this.getTaskInformation()
            ]
        }
    },
    methods: {
        applyDateFormat,
        translateTaskFrequency,
        translateTimeUnit,
        ISO8601StringToDisplayString,
        getTaskInformation() {
            return this.$store.dispatch("tasksModule/fetchTaskGeneral", { clientId: this.clientId, projectId: this.projectId, taskId: this.taskId })
                .then(result => { 
                    this.taskInformation = result
                })
        }
    },
}
</script>