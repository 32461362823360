<template>
    <BasicInformationCard title="Werkschema"
    :editRouterLink="{ name: routeNames.TASK_DETAIL_EDIT_WORK_SCHEMA, params: { clientId: clientId, projectId: projectId , taskId: taskId} }"
    :promises.sync="promises">
        <template v-slot:content>
            <DayPlanningComponent v-model="timeSlots" max-height />
        </template>
    </BasicInformationCard>
</template>

<script>
import BasicInformationCard from '../shared/BasicInformationCard.vue'
import routeNames from "@/router/RouteNames"
import DayPlanningComponent from "@/components/planning/DayPlanningComponent";

export default {
    name: "TaskWorkSchemaComponent",
    props: {
        clientId: {
            type: String,
            required: true
        },
        projectId: {
            type: String,
            required: true
        },
        taskId: {
            type: String,
            required: true
        }
    },
    components: {
        BasicInformationCard,
        DayPlanningComponent
    },
    data() {
        return {
            routeNames,
            promises: [],
            timeSlots: {
                MONDAY: null,
                TUESDAY: null,
                WEDNESDAY: null,
                THURSDAY: null,
                FRIDAY: null,
                SATURDAY: null,
                SUNDAY: null
            }
        }
    },
    created() {
        this.promises.push(this.$store.dispatch('tasksModule/fetchTimeSlots', { clientId: this.clientId, projectId: this.projectId, taskId: this.taskId })
            .then((result) => {
                if (result) {
                    this.timeSlots = result
                }
            })
        )
    }
}
</script>
<style>
.v-card__text {
    padding: 0;

}
</style>